<template>
  <div>
    <div
      v-if="isLoadingModule && !data.id"
      style="height: 400px"
      class="d-flex justify-content-center align-items-center"
    >
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <b-row v-else>
      <b-col md="4">
        <b-card>
          <div class="profile-image p-0 d-flex justify-content-center">
            <b-avatar
              rounded="none"
              size="114"
              variant="light"
              :src="data.profilephoto"
              :text="data && data.f_name ? data.f_name.substring(0, 1) : ''"
              style="
                width: 114px;
                height: 114px;
                object-fit: cover;
                border-radius: 10px;
              "
            />
          </div>
          <hr class="mb-2" />
          <!-- follower projects rank -->
          <h4 class="text-muted">Trainer Details</h4>
          <div
            v-if="isSideDataLoading && !data.id"
            style="height: 400px"
            class="d-flex justify-content-center align-items-center"
          >
            <b-spinner label="Loading..."></b-spinner>
          </div>
          <div v-else>
            <b-col cols="12" class="pt-1 d-flex">
              <h6
                class="details-label"
              >
                Username:
              </h6>
              <p class="ml-1 mb-0 button">
                {{ data ? data.f_name : "" }}
              </p>
            </b-col>

            <b-col cols="12" class="font-weight-bolder pt-1 d-flex">
              <h6
              class="details-label"
              >
                Email:
              </h6>
              <p class="ml-1 mb-0 button">
                {{ data ? data.email : "" }}
              </p>
            </b-col>
            <b-col cols="12" class="pt-1 d-flex">
              <h6
               class="details-label"
              >
                Address:
              </h6>
              <p class="ml-1 mb-0 button">{{ data ? data.address : "" }}</p>
            </b-col>
            <b-col cols="12" class="pt-1 d-flex">
              <h6
                class="details-label"
              >
                Date of Birth:
              </h6>
              <p class="ml-1 mb-0 button">
                {{ data ? moment(data.dob).format('DD/MM/YYYY') : "" }}
              </p>
            </b-col>
            <b-col cols="12" class="pt-1 d-flex">
              <h6
                class="details-label"
              >
                Specialized:
              </h6>
              <p class="button ml-1 mb-0">
                {{ data.specialization}}
              </p>
            </b-col>
            <b-col cols="12" class="pt-1 d-flex">
              <h6
                class="details-label"
              >
                Language:
              </h6>
              <p class="ml-1 mb-0 button">
                {{ data && data.language ? data.language.name : "English" }}
              </p>
            </b-col>
            <b-col cols="12" class="pt-1 d-flex">
              <h6
                class="details-label"
              >
                Country:
              </h6>
              <p class="ml-1 mb-0 button">
                {{ data.country ? data.country.name : "" }}
              </p>
            </b-col>
            <b-col cols="12" class="pt-1 d-flex">
              <h6
                class="details-label"
              >
                Height :
              </h6>
              <p class="ml-1 mb-0 button">
                {{ data ? data.height : "" }}
              </p>
            </b-col>
            <b-col cols="12" class="d-flex pt-1">
              <h6
                class="font-weight-bolder"
                style="font-family: 'Public Sans', sans-serif; font-size: 15px"
              >
                Weight :
              </h6>
              <p class="ml-1 mb-0 button">
                {{ data ? data.weight : "" }}
              </p>
            </b-col>
            <b-col cols="12" class="d-flex pt-1">
              <h6
                class="font-weight-bolder"
                style="font-family: 'Public Sans', sans-serif; font-size: 15px"
              >
                Contact :
              </h6>
              <p class="ml-1 mb-0 button">
                {{ data ? data.phone : "" }}
              </p>
            </b-col>

            <b-col cols="12" class="d-flex pt-1">
              <h6
                class="font-weight-bolder"
                style="font-family: 'Public Sans', sans-serif; font-size: 15px"
              >
                Period of access: 
              </h6>
              <p class="ml-1 mb-0 button">
              <span v-if="$route.params.view">
                {{data.plans ? data.plans.name :'' }}
              </span>
              <span v-else>
                {{ membershipEndDate?moment(membershipEndDate).format('DD/MM/YYYY'):'' }}
              </span>
              </p>
              <b-button v-if="isMembershipDateAfterToday"
                class="ml-2"
                variant="primary"
                @click="openPlanModal()"
                size="sm"
                >Plan Renewal</b-button
              >
            </b-col>
            <b-col cols="12" class="d-flex pt-1">
              <h6
                class="font-weight-bolder"
                style="font-family: 'Public Sans', sans-serif; font-size: 15px"
              >
                ID proof:
              </h6>
              <b-avatar
                rounded="none"
                size="50"
                variant="light"
                :src="data ? data.idproof : ''"
                style="
                  width: 50px;
                  height: 50px;
                  object-fit: cover;
                  border-radius: 10px;
                "
                class="ml-1 mb-0"
              />
            </b-col>
            <b-col cols="12" class="d-flex pt-1">
              <h6
                class="font-weight-bolder"
                style="font-family: 'Public Sans', sans-serif; font-size: 15px"
              >
                PT insurance:
              </h6>
              <b-avatar
                rounded="none"
                size="50"
                variant="light"
                :src="data ? data.ptinsurance : ''"
                style="
                  width: 50px;
                  height: 50px;
                  object-fit: cover;
                  border-radius: 10px;
                "
                class="ml-1 mb-0"
              />
            </b-col>
            <b-col cols="12" class="pt-1 d-flex" v-if="isProfileType">
              <h6 class="inputValue font-weight-bolder">Suspended Reason:</h6>
              <p class="inputValue ml-1 mb-0">
                {{ data.suspend_reason }}
              </p>
            </b-col>
            <b-col
              class="d-flex flex-wrap justify-content-between"
              v-if="!isProfileType"
            >
              <b-button
                @click="openModal('Temporary')"
                variant="outline-danger"
                class="button mt-2 mr-1"
              >
                Temporary Suspend
              </b-button>
              <b-button
                @click="openModal('Permanent')"
                variant="outline-danger"
                class="button mt-2"
              >
                Suspend
              </b-button>
            </b-col>
          </div>
          <!--/ follower projects rank -->
        </b-card>

        <div>
          <b-modal
            v-model="suspend_modal"
            no-close-on-backdrop
            hide-footer
            centered
            size="md"
          >
            <validation-observer ref="simpleRules">
              <b-form ref="myFormMain">
                <b-row class="p-1">
                  <b-col md="12" class="my-2 text-center">
                    <!-- {{ CustomerData }} -->
                    <h3
                      class="font-weight-bolder"
                      style="font-size:26px; font-family:Public Sans, sans's serif"
                    >
                      Suspend Trainer
                    </h3>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group>
                      <validation-provider #default="{ errors }" name="Reason">
                        <b-form-input
                          type="text"
                          v-model="reason"
                          maxlength="100"
                          placeholder="Write a reason"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" v-if="suspend_type == 'Temporary'">
                    <b-form-group>
                      <label class="font-weight-bold mt-1" for="h-first-name"
                        >How long do you want to suspend</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="UserID"
                      >
                        <v-select
                          type="text"
                          v-model="week"
                          :options="selectWeakOptions"
                          placeholder="Select Week"
                        ></v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- @click="Congo" -->
                  <b-col md="12" class="d-flex justify-content-center mt-2">
                    <b-button
                      type="submit"
                      @click.prevent="handleTrainerSubmit()"
                      variant="primary"
                      class="mr-1"
                      style="font-family: Public Sans"
                    >
                      Submit
                    </b-button>
                    <b-button
                      type="reset"
                      variant="primary"
                      @click="closemodel()"
                      style="font-family: Public Sans"
                    >
                      Cancel
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-modal>
        </div>

        <div>
          <b-modal
            hide-footer
            v-model="assignTrainer"
            no-close-on-backdrop
            centered
            size="md"
          >
            <b-tabs pills v-model="activeTab">
              <b-tab title="All Customer Assign" value="allCustomers" >
                <b-row class="p-2">
                  <b-col md="12" class="d-flex align-items-center flex-column">
                    <h2
                      style="font-size: 26px"
                      class="font-weight-bolder lableTitle"
                    >
                      Clients assigned to trainers
                    </h2>
                    <p class="labelTitle">
                      "Assign to trainers with our gym management software."
                    </p>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group>
                      <label class="font-weight-bolder mt-1" for="h-trainer"
                        >Select Trainer</label
                      >
                      <b-form-input
                        id="h-trainer"
                        v-model="newSearch"
                        @input="handleTrainerSearch()"
                        placeholder="Name"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <h2 class="font-weight-bold text-dark mr-1">
                      {{ AllTrainer.length }} Trainers
                    </h2>
                  </b-col>
                  <b-col md="12">
                    <!-- Loading indicator outside the loop -->
                    <div
                      v-if="isDataLoading"
                      style="height: 400px"
                      class="d-flex justify-content-center align-items-center"
                    >
                      <b-spinner label="Loading..."></b-spinner>
                    </div>
                    <div v-else>
                      <!-- Loop through trainers -->
                      <div
                        v-for="(item, index) in AllTrainer"
                        :key="index"
                        class="mt-2"
                      >
                        <div class="w-100">
                          <div class="d-flex w-100 align-items-center">
                            <div class="mr-1">
                              <b-avatar size="32" :src="item.profilephoto" />
                            </div>
                            <div class="w-100">
                              <h5 class="m-0 lableTitle">{{ item.f_name }}</h5>
                              <small>{{
                                setspecialization(item.all_specialization)
                              }}</small>
                            </div>
                            <div
                              class="d-flex w-100 align-items-center justify-content-end"
                            >
                              <b-button
                                variant="primary"
                                :disabled='submiting'
                                @click="handleSubmitForm(item.id)"
                              >
                                <span>Select</span>
                              </b-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-tab>

              <b-tab
                title="Particular Customer Assign"
                value="particularCustomer"
              >
                <validation-observer ref="Rules">
                  <b-row class="p-2">
                    <b-col
                      md="12"
                      class="d-flex align-items-center flex-column"
                    >
                      <h2
                        style="font-size: 26px"
                        class="font-weight-bolder lableTitle"
                      >
                        Clients assigned to trainers
                      </h2>
                      <p class="labelTitle">
                        "Assign to trainers with our gym management software."
                      </p>
                    </b-col>
                    <b-col md="12">
                      <h2 class="font-weight-bold text-dark mr-1">
                        {{
                          customers.length ? customers.length + " Clients" : ""
                        }}
                      </h2>
                    </b-col>
                    <b-col md="12">
                      <!-- Loading indicator outside the loop -->
                      <div
                        v-if="customers.length === 0"
                        class="w-100 d-flex justify-content-center align-items-center"
                      >
                        <p>No customers available.</p>
                      </div>
                      <div v-else>
                        <div
                          v-if="isDataLoading"
                          style="height: 400px"
                          class="d-flex justify-content-center align-items-center"
                        >
                          <b-spinner label="Loading..."></b-spinner>
                        </div>
                        <div v-else>
                          <!-- Loop through customers -->
                          <div
                            v-for="(item, index) in customers"
                            :key="index"
                            class="mt-2"
                          >
                            <div class="w-100">
                              <div class="d-flex w-100 align-items-center">
                                <div class="mr-1">
                                  <b-avatar
                                    size="32"
                                    :src="item.profilephoto"
                                  />
                                </div>
                                <div class="w-100 font-weight-bolder">
                                  <h5 class="m-0 lableTitle">
                                    {{ item.f_name }}
                                  </h5>
                                  <small>
                                    {{
                                      item &&
                                      item.customer_goal 
                                        ? item.customer_goal.goal.name
                                        : ""
                                    }}
                                  </small>
                                </div>
                                <div
                                  class="d-flex w-100 align-items-center justify-content-end"
                                >
                                  <validation-provider
                                    #default="{ errors }"
                                    :name="'Select Trainer ' + (index + 1)"
                                    rules="required"
                                  >
                                    <v-select
                                      v-model="item.selectedTrainer"
                                      style="width: 200px"
                                      placeholder="Select Trainer"
                                      :options="dropdownTrainers"
                                      :clearable="false"
                                      label="f_name"
                                      class="d-inline-block ml-5"
                                    />
                                    <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                  </validation-provider>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-col>

                    <b-col
                      v-if="customers.length > 0"
                      md="12"
                      class="d-flex justify-content-center mt-3"
                    >
                      <b-button
                        type="submit"
                        variant="primary"
                        class="button mr-1"
                        @click="submitSelectedCustTrainer()"
                      >
                        Submit
                      </b-button>
                      <b-button
                        type="reset"
                        variant="primary"
                        class="button"
                        @click="close"
                      >
                        Cancel
                      </b-button>
                    </b-col>
                  </b-row>
                </validation-observer>
              </b-tab>
            </b-tabs>
          </b-modal>
        </div>
      </b-col>

      <b-col class="col-md-8">
        <b-tabs pills>
          <b-tab v-if='!$route.params.view'>
            <template #title>
              <span>Clients</span>
            </template>

            <b-modal
              hide-footer
              v-model="changetrainer"
              no-close-on-backdrop
              centered
              size="md"
            >
              <b-row class="p-2">
                <div class="col-md-12 d-flex justify-content-center">
                  <b-col md="12" class="mx-auto text-center">
                    <h2
                      class="font-weight-bolder"
                      style="
                        font-family: 'Public Sans', sans-serif;
                        font-size: 15px;
                      "
                    >
                      Clients assigned to trainers
                    </h2>
                    <p>
                      "Assign to trainers with our gym management software."
                    </p>
                  </b-col>
                </div>
                <b-col cols="12">
                  <b-form-group>
                    <label class="mt-1 font-weight-bold" for="h-trainer"
                      >Add Trainer</label
                    >
                    <b-form-input id="h-trainer" placeholder="Name" />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-modal>

            <b-row class="mb-2">
              <!-- Per Page Selector and Assign Client Button -->
              <b-col md="6" class="d-flex align-items-center">
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  v-model="per_page"
                  class="per-page-selector d-inline-block mx-1"
                  :options="['5', '10', '20', '50', '100']"
                  @input="changePagination()"
                />
                <b-button
                  variant="primary"
                  @click="openClientModal"
                  class="mx-1"
                >
                  <span class="text-nowrap font-weight-bold"
                    >Assign Client</span
                  >
                </b-button>
              </b-col>

              <!-- Search Input and Search Term Selector -->
              <b-col md="6" class="d-flex align-items-center mt-1">
                <v-select
                  style="width: 200px"
                  placeholder="Search..."
                  :options="
                    searchTerm.name === 'Goal' ? getOptions('goal') : allCustomers
                  "
                  :label="searchTerm.displayName"
                  class="d-inline-block ml-5"
                  v-model="search"
                  @input="handleSearch()"
                />
                <v-select
                  style="width: 150px !important"
                  :clearable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :searchable="false"
                  v-model="searchTerm"
                  placeholder="Select"
                  class="per-page-selector d-inline-block ml-2"
                  label="name"
                  :options="searchOptions"
                  @input="changeSearch()"
                />
              </b-col>
            </b-row>

            <b-modal
              hide-footer
              v-model="AddNewUser"
              no-close-on-backdrop
              centered
              size="md"
            >
              <b-row class="p-2">
                <b-col md="12">
                  <h2
                    class="font-weight-bolder mx-auto text-center"
                    style="
                      font-family: 'Public Sans', sans-serif;
                      font-size: 26px;
                    "
                  >
                    Client assign to trainer
                  </h2>
                  <p class="mx-auto text-center">
                    Trainers should select clients according to their <br />
                    specific talents
                  </p>
                </b-col>

                <div class="col-md-12">
                  <b-col cols="12">
                    <b-form-group>
                      <label class="font-weight-bold mt-1" for="h-trainer"
                        >Add Clients</label
                      >
                      <b-form-input
                        id="h-trainer"
                        v-model="newSearch"
                        @input="handleClientSearch()"
                        placeholder="Name"
                      />
                    </b-form-group>
                  </b-col>
                  <div
                    v-if="!isLoadingCustomer && trainerNullCustomer.length == 0"
                    class="w-100 d-flex justify-content-center align-items-center"
                  >
                    <p class="font-weight-bolder mt-2 text-danger text-large">
                      No customers available without trainer.
                    </p>
                  </div>
                  <div>
                    <div
                      v-if="isLoadingCustomer"
                      style="height: 200px"
                      class="w-100 d-flex justify-content-center align-items-center"
                    >
                      <b-spinner label="Loading..."></b-spinner>
                    </div>
                    <div v-else>
                      <b-col md="12">
                        <h3
                          v-if="
                            trainerNullCustomer.length === 0
                              ? ''
                              : trainerNullCustomer.length
                          "
                          for=""
                          class="font-weight-bolder"
                          style="
                            font-family: 'Public Sans', sans-serif;
                            font-size: 22px;
                          "
                        >
                          {{
                            trainerNullCustomer.filter(
                              (customer) => customer.trainer_id === null
                            ).length
                          }}
                        </h3>
                      </b-col>
                      <b-col>
                        <b-col
                          md="12"
                          v-for="(item, index) in trainerNullCustomer"
                          :key="index"
                          class="mt-2"
                        >
                          <div class="d-flex w-100 align-items-center">
                            <div class="mr-1">
                              <b-avatar
                                size="32"
                                :src="item ? item.profilephoto : ''"
                              />
                            </div>
                            <div class="w-100">
                              <h4 class="m-0 button font-weight-bolder">
                                {{ item.f_name }}
                              </h4>
                              <small>
                                {{
                                  item.customer_goal &&
                                  item.customer_goal.goal
                                    ? item.customer_goal.goal.name
                                    : ""
                                }}
                              </small>
                            </div>
                            <div
                              class="d-flex w-100 align-items-center justify-content-end"
                            >
                              <b-button
                                @click="toggleSelection(item)"
                                :variant="
                                  selectedCustomers.indexOf(item.id) !== -1
                                    ? 'danger'
                                    : 'primary'
                                "
                              >
                                <span class="button">
                                  {{
                                    selectedCustomers.indexOf(item.id) !== -1
                                      ? "REMOVE"
                                      : "ADD"
                                  }}
                                </span>
                              </b-button>
                            </div>
                          </div>
                        </b-col>
                      </b-col>
                    </div>
                  </div>
                </div>

                <b-col
                  v-if="trainerNullCustomer.length > 0"
                  md="12"
                  class="d-flex justify-content-center mt-3"
                >
                  <b-button
                    type="submit"
                    variant="primary"
                    class="button mr-1"
                    @click="submitSelectedCustomers()"
                  >
                    Submit
                  </b-button>
                  <b-button
                    type="reset"
                    variant="primary"
                    class="button"
                    @click="close"
                  >
                    Cancel
                  </b-button>
                </b-col>
              </b-row>
            </b-modal>

            <b-card no-body style="overflow: hidden">
              <b-row>
                <b-table
                  ref="refUserListTable"
                  :busy="isLoading"
                  style="font-size: 13px"
                  class="mt-1 w-100 position-relative"
                  :items="customers"
                  responsive
                  :fields="tableColumns"
                  primary-key="id"
                  show-empty
                  empty-text="No matching records found"
                  @sort-changed="onSortChanged"
                  id="my-table"
                >
                  <!-- :sort-desc.sync="isSortDirDesc" -->
                  <template #table-busy>
                    <div class="text-center text-secondary my-2">
                      <b-spinner class="align-middle"></b-spinner>
                    </div>
                  </template>
                  <template #cell(id)="data">
                    <span
                      class="text-primary font-weight-bolder"
                      style="font-size: 15px; font-family: Public Sans"
                    >
                      {{ data.index + 1 }}
                    </span>
                  </template>

                  <template #cell(f_name)="data">
                    <div
                      class="font-weight-bolder cursor-pointer d-flex align-items-center w-100"
                      style="font-size: 15px; font-family: Public Sans"
                    >
                      <span class="mr-1">
                        <b-avatar
                          size="32"
                          :src="data.item.avatar"
                          :text="data.item.f_name.substring(0, 1)"
                        />
                      </span>
                      {{ data.item.f_name }}
                    </div>
                  </template>

                  <template #cell(goal)="data">
                    <div class="d-flex w-100 align-items-center">
                      <p
                        class="m-0 align-text-center font-weight-bolder"
                        style="font-size: 15px; font-family: Public Sans"
                      >
                        {{
                          data.item &&
                          data.item.customer_goal  && data.item.customer_goal.goal
                            ? data.item.customer_goal.goal.name
                            : ""
                        }}
                      </p>
                    </div>
                  </template>
                  <!-- <template #cell(CurrentWaistMeasurement)='data'>
                                    <p>{{ data.item.CurrentWaistMeasurement }}</p>
                                </template> -->
                  <template #cell(progress)="data">
                    <div class="d-flex align-items-center font-weight-bolder">
                      <span
                        class="font-weight-bolder"
                        style="
                          font-weight: 400;
                          font-size: 15px;
                          font-family: Public Sans;
                        "
                        @input="filterTable()"
                        >{{ data.item.progress ? data.item.progress.toFixed(2):0  }}%</span
                      >
                      <b-progress
                        :value="data.item.progress"
                        class="w-100 ml-1"
                        :variant="data.item.progress < 50 ? 'danger' : 'success'"
                      ></b-progress>
                    </div>
                  </template>
                  <template #cell(phone)="data">
                    <p class="font-weight-bolder" style="font-size: 15px">
                      {{ data.item.phone }}
                    </p>
                  </template>
                </b-table>
              </b-row>
              <div class="w-100">
                <b-row>
                  <div class="w-100 d-flex justify-content-between p-2">
                    <!-- Pagination -->
                    <div>
                      <span class="text-nowrap">
                        Showing {{ paginationStart }} to {{ paginationEnd }} of
                        {{ paginationTotal }}</span
                      >
                    </div>
                    <b-pagination
                      :total-rows="paginationTotal"
                      :per-page="per_page"
                      v-model="paginationValue"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                      @input="changePagination()"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </b-row>
              </div>
            </b-card>
          </b-tab>
          <b-tab>
            <template #title>
              <span>Certifications</span>
            </template>
            <b-row>
              <div
                v-if="ptcertificate.length > 0 || ptinsurance.length > 0"
                class="d-flex"
              >
                <b-col lg="4" md="6" sm="12" class="mb-3">
                  <b-img fluid :src="ptcertificate" class="rounded p-1 image" />
                </b-col>
                <b-col lg="4" md="6" sm="12" class="mb-3">
                  <b-img fluid :src="ptinsurance" class="rounded p-1 image" />
                </b-col>
              </div>
              <b-col
                md="12"
                v-else
                class="d-flex justify-content-center align-item-center"
              >
                <div>
                  <p>There is no certificate available</p>
                </div>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-col>
      <div>
        <b-modal
          v-model="planRenew"
          no-close-on-backdrop
          hide-footer
          centered
          size="md"
        >
          <validation-observer ref="planRenewal">
            <b-form ref="newPlan">
              <b-row class="p-1">
                <b-col md="12" class="my-2 text-center">
                  <h3
                    style="
                      font-weight: 600;
                      font-size: 26px;
                      font-family: Public Sans;
                    "
                  >
                    Renew Plan
                  </h3>
                </b-col>
                <b-col md="12">
                  <label for="input">Select Plans</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Select Plan"
                  >
                    <v-select
                      placeholder="Select Your Plans"
                      label="name"
                      :searchable="false"
                      :options="getOptions('plan')"
                      v-model="plans"
                    ></v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>

                <!-- @click="Congo" -->
                <b-col md="12" class="d-flex justify-content-center mt-2">
                  <b-button
                    type="submit"
                    variant="primary"
                    class="mr-1"
                    @click.prevent="handleFormSubmission()"
                    style="font-family: Public Sans"
                  >
                    Submit
                  </b-button>
                  <b-button
                    type="reset"
                    @click="CloseModal()"
                    variant="primary"
                    style="font-family: Public Sans"
                  >
                    Cancel
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-modal>
      </div>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BTabs,
  BImg,
  BTab,
  BCardText,
  BTable,
  BCard,
  BAvatar,
  BBadge,
  BCol,
  BRow,
  BFormInput,
  BFormGroup,
  BProgress,
  BCardBody,
  BMedia,
  BMediaAside,
  BModal,
  BForm,
  BPagination,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import VueApexCharts from "vue-apexcharts";
import apexChatData from "../../charts-and-maps/charts/echart/EchartArea.vue";
import calendar from "../../apps/calendar/Calendar.vue";
import Workout from "./videoManagement/workoutVideoManagement.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import axios from "@/component/axios";
import { kFormatter } from "../../../@core/utils/filter";
import moment from "moment";
import _ from "lodash";
export default {
  components: {
    BPagination,
    BMedia,
    BMediaAside,
    ValidationProvider,
    ValidationObserver,
    required,
    BBadge,
    BCardText,
    BModal,
    BCardBody,
    BSpinner,
    BTabs,
    BTab,
    BCard,
    BBadge,
    BAvatar,
    BCol,
    BImg,
    BFormInput,
    BButton,
    BForm,
    BProgress,
    BRow,
    vSelect,
    BTable,
    BFormGroup,
    VueApexCharts,
    apexChatData,
    calendar,
    Workout,
  },
  data() {
    return {
      newCustomer: { name: "customer", value: "customer" },

      // tableColumns: ['NO', 'Client', 'Goal', 'Progress', 'Contact',],
      tableColumns: [
        // { key: 'id', label: '#', sortable: true },
        // { key: 'invoiceStatus', sortable: true },
        {
          key: "id",
          label: "NO",
          sortable: true,
        },
        {
          key: "f_name",
          label: "Client",
          sortable: true,
        },
        {
          key: "goal",
          label: "GOAL",
          type: "dropdown",
          displayName: "name",
        },
        // { key: 'total', sortable: true, formatter: val => `$${val}` },
        // { key: 'balance', sortable: true },
        {
          key: "progress",
          label: "Progress",
          sortable: true,
        },
        {
          key: "phone",
          label: "Contact",
        },
      ],
      activeTab: "allCustomers",
      columnFilters: {},
      searchTerm: {
        name: "Client",
        value: "customer",
        type: "dropdown",
        displayName: "f_name",
      },
      isDataLoading: false,
      trainerNullCustomer: [],
      isLoadingCustomer: false,
      search: "",
      isSuspend: true,
      Parmasuspended: false,
      assignTrainer: false,
      submiting:false,
      newSearch: "",
      isSortDirDesc: true,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      sortBy: "",
      orderBy: true ? "ASC" : "DESC",
      kFormatter,
      suspened: false,
      changetrainer: false,
      chan: false,
      fullname: "",
      nocerti: false,
      gender: "",
      isSideDataLoading: false,
      goal: "",
      height: "",
      weight: "",
      email: "",
      membershipDetail: [],
      page: "",
      contact: "",
      language: "",
      country: "",
      plan: "",
      isLoading: false,
      moment,
      data: {},
      AddNewUser: false,
      trainier: "",
      trainee: false,
      ParmaSuspendReason: "",
      selected: null,
      ptcertificate: "",
      ptinsurance: "",
      view_count: "",
      AllCustomer: [],
      videosArray: [],
      customers: [],
      AllTrainer: [],
      totalTairner: [],
      paginationStart: 0,
      paginationEnd: 0,
      paginationValue: 1,
      paginationTotal: 0,
      per_page: 10,
      searchTerm: {
        name: "Client",
        value: "customers",
        type: "dropdown",
        displayName: "f_name",
      },
      searchOptions: [
        {
          name: "Client",
          value: "customers",
          type: "dropdown",
          displayName: "f_name",
        },
        { name: "Goal", value: "goal", type: "dropdown", displayName: "name" },
      ],
      SuspendReason: "",
      membershipEndDate:'',
      suspendWeak: null,
      selectWeakOptions: [
        { label: "1 Week", value: "7 days" },
        { label: "2 Week", value: "14 days" },
      ],
      Certificate: [],
      trainerData: {},
      trainersList: [],
      isLoadingModule: false,
      option: {
        xAxisData: [
          "7/12",
          "8/12",
          "9/12",
          "10/12",
          "11/12",
          "12/12",
          "13/12",
          "14/12",
          "15/12",
          "16/12",
          "17/12",
          "18/12",
          "19/12",
          "20/12",
        ],
        series: [
          {
            name: "Point One",
            type: "line",
            stack: "Total",
            areaStyle: {},
            showSymbol: false,
            lineStyle: {
              width: 0,
            },
            data: [
              220, 332, 281, 334, 290, 430, 310, 350, 510, 550, 720, 650, 760,
              850,
            ],
          },
        ],
        staticUsers: [],
      },
      selectedCustomers: [],
      params: {},
      suspend_modal: false,
      reason: "",
      week: "",
      suspend_type: "",
      planRenew: false,
      plans:'',
      originalDate : moment().format("DD/MM/YYYY"),
      allCustomers:[],
      dropdownTrainers:[]
    };
  },
  mounted() {
    // this.getVideo();
    // this.getTrainerData();
    this.getTrainerProfileData();
    // this.getTrainerCustomers()
    // this.getTrainerCustomerData();
    this.getTrainerCustomers();
    this.getAllTrainer();
    this.getTrainerNull();
  },
  computed: {
    
    isProfileType() {
      return this.$route.query.type === "profile";
    },
    isMembershipDateAfterToday(){
      return moment(new Date(this.membershipEndDate))< (moment(new Date()));
    },
    filteredItems() {
      // Custom method to filter items based on columnFilters
      return this.items.filter((item) => {
        return this.fields.every((field) => {
          const filterValue = this.columnFilters[field.key];
          if (filterValue) {
            return String(item[field.key])
              .toLowerCase()
              .includes(filterValue.toLowerCase());
          }
          return true;
        });
      });
    },
    setspecialization(){
      return(data)=>{
        if(data){

          let arr = data.map((item)=>{
            return item.specialization ? item.specialization.name :''
          })
          
          return arr.filter(item=> item ).join(', ')
        }
      return ''
      }
    }
  },

  methods: {
    async getTrainerCustomers(){
      await axios({
        method: "GET",
        url: `${this.baseApi}trainer/get-clients/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        // params: { search: searchQuery },
      })
        .then((json) => {
          this.allCustomers = json.data.data
        })
    },
    CloseModal(){
      this.planRenew = false
    },
    onSortChanged(e) {
      this.sortBy = e.sortBy;
      this.orderBy = e.sortDesc ? "DESC" : "ASC";
      // console.log(this.sortBy)
      // console.log(this.orderBy)
      this.getTrainerProfileData();
    },
    async getTrainerNull() {
      this.isLoadingCustomer = true;
      let params = {};
      if (this.newSearch) {
        params.search = this.newSearch.id ? this.newSearch.id : this.newSearch;
        params.type = this.newCustomer.value;
      }
      await axios({
        method: "GET",
        url: `${this.baseApi}customer/all?page=1&per_page=10000`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        params: params,
        // params: { search: searchQuery },
      })
        .then((json) => {
          this.trainerNullCustomer = json?.data.data.data
            .filter((customer) => customer.trainer_id === null)
            .slice(0, 10);
        })
        .catch((error) => {
          console.log(error, "error");
        })
        .finally(() => {
          this.isLoadingCustomer = false;
        });
    },
    handleSearch: _.debounce(function () {
      this.getTrainerProfileData();
    }, 1000),

    formatDate(date) {
      return moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
    },
    handleTrainerSearch: _.debounce(function () {
      this.getAllTrainer();
    }, 1000),
    changePagination() {
      this.getTrainerProfileData();
    },
    toggleSelection(item) {
      let index = this.selectedCustomers.indexOf(item.id);
      if (index == -1) {
        this.selectedCustomers.push(item.id);
      } else {
        this.selectedCustomers.splice(index, 1);
      }
      this.$forceUpdate();
    },

    handleTrainerSubmit() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success === false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Please fill in all fields",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          if (this.customers.length == 0) {
            this.handleSuspendTrainer();
          } else {
            this.assignTrainer = true;
            this.suspend_modal = false;
          }
        }
      });
    },
    async handleSuspendTrainer() {
      let customerData = {
        suspend_type: this.suspend_type,
        user_id: this.data.id,
        suspend_reason: this.reason,
      };
      if (this.suspend_type == "Temporary") {
        customerData.days = this.week ? this.week.value : "";
      }
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}dashboard/suspend-user`,
        data: JSON.stringify(customerData),
      };
      await axios(requestOptions)
        .then((response) => {
          if (this.customers.length == 0) {
            this.$swal({
              title: "Submitted",
              text: response.data.message
                ? `${response.data.message}`
                : response.data.success
                ? `${response.data.success}`
                : "Update Successfully",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.$router.push({ path: `/trainermanagement` });
          }
          this.$forceUpdate();
          this.suspend_modal = false;
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.$swal({
              title: "Error!",
              text: error.response.data.message,
              icon: "error",
              timer: 5000,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          } else {
            this.$swal({
              title: "Error!",
              text: `${error}`,
              icon: "error",
              timer: 5000,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
        });
    },
    async submitSelectedCustTrainer() {
      // Validate the form using simple rules validation
      this.$refs.Rules.validate().then(async (success) => {
        if (success) {
          this.handleTrainerSelectForm();
        } else {
          // Validation failed, display an error message or take appropriate action
          this.$swal({
            title: "Validation Error",
            text: "Please fill in all required fields correctly.",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      });
    },

    async handleTrainerSelectForm() {
      this.handleSuspendTrainer();

      const oldTrainerId = parseInt(this.$route.params.id);
      let arr = this.customers.map((item) => {
        return {
          trainer_id: item.selectedTrainer ? item.selectedTrainer.id : 0,
          client_id: item.id,
        };
      });
      const customerData = {
        // oldTrainerId: oldTrainerId,
        assignClient: arr,
      };

      const requestOptions = {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}trainer/individual-assign`,
        data: JSON.stringify(customerData),
      };

      await axios(requestOptions)
        .then((response) => {
          this.$swal({
            title: "Submitted",
            text: " Trainer Assigned Successfully",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          // Clear any selection or close modal after submission
          this.AddNewUser = false;
          this.assignTrainer = false;
          this.$router.push({ path: `/trainermanagement` });
          this.$forceUpdate();
        })
        .catch((error) => {
          let errorMessage = "";
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            errorMessage = error.response.data.message;
          }
          this.$swal({
            title: "Error",
            html: errorMessage || "An error occurred.",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          console.error("Error assigning trainer:", error);
        });
    },

    async submitSelectedCustomers() {
      const customerData = {
        id: this.selectedCustomers,
      };
      const requestOptions = {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}trainer/assign-customer/${this.$route.params.id}`,
        data: JSON.stringify(customerData),
      };

      await axios(requestOptions)
        .then((response) => {
          this.$swal({
            title: "Submitted",
            text: response.data.message
              ? `${response.data.message}`
              : response.data.success
              ? `${response.data.success}`
              : "Update Successfully",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.AddNewUser = false;
          this.getTrainerProfileData();
          this.getTrainerNull();
          this.$forceUpdate();
        })
        .catch((error) => {
          console.error("Error adding customer:", error);
        });
    },
    // async getTrainerCustomerData() {
     
    //   await axios({
    //     method: "GET",
    //     url: `${this.baseApi}trainer/show/${this.$route.params.id}`,
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${this.accessToken}`,
    //     },
    //   })
    //     .then((json) => {
          
    //       //     ? json.data.data[0]
    //       //     : json.data.data;
    //     })
    //     .catch((error) => console.log(error, "error"))
    //     .finally(() => {
    //       this.isLoading = false;
    //     });
    // },
    async getTrainerProfileData() {
      this.isLoadingModule = true;
      this.isSideDataLoading = true;
      this.isLoading =true
      let params = {};
      params.page = this.paginationValue;
      params.per_page = this.per_page;
      if (this.search) {
        params.search = this.search.id ? this.search.id : this.search;
        params.type = this.searchTerm.value;
      }
      if (this.sortBy) {
        params.sortBy = this.sortBy;
        params.orderBy = this.orderBy;
      }
      await axios({
        method: "GET",
        url: `${this.baseApi}trainer/show/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        params: params,

      })
        .then((json) => {
          this.data = json?.data?.data.trainer;
          this.ptcertificate = this.data.ptcertificate;
          this.ptinsurance = this.data.ptinsurance;
          let arr = this.data.all_specialization.map((item)=>{
            return item.specialization?item.specialization.name:''
          })
          this.data.specialization = arr.filter(inner => inner).join(', ')
          this.customers = json?.data?.data.trainer.trainers_customers;
          this.membershipDetail = json.data.data.membershipDetail;
          this.membershipEndDate = this.membershipDetail.length>0 ? this.membershipDetail[0].enddate :''  
          this.page = json?.data?.data.pagination;
          this.paginationTotal = this.page.total;
          this.paginationStart = this.page.current_page;
          this.paginationEnd = this.page.per_page;
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {
          this.isSideDataLoading = false;
          this.isLoadingModule = false;
          this.isLoading =false

        });
    },
    handleClientSearch: _.debounce(function () {
      (this.trainerNullCustomer = []), this.getTrainerNull(this.newSearch);
    }, 1000),

    changeSearch() {
      if (this.search) {
        this.getTrainerProfileData();
      }
    },
    isImage(data) {
      const imageExtensions = [
        ".jpg",
        ".jpeg",
        ".png",
        ".gif",
        ".bmp",
        ".webp",
        ".svg",
        ".tiff",
      ];
      return imageExtensions.some((extension) =>
        String(data).endsWith(extension.toLowerCase())
      );
    },

    async handleSubmitForm(id) {
      await this.handleForm(id);
    },
    async handleForm(id) {
      this.handleSuspendTrainer();
      const oldTrainer_id = parseInt(this.$route.params.id);
      const customerData = {
        newtrainer_id: id,
        oldtrainer_id: oldTrainer_id,
      };
      this.submiting=true

      const requestOptions = {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}dashboard/assignnew-trainer`,
        data: JSON.stringify(customerData),
      };

      await axios(requestOptions)
        .then(() => {
          this.$swal({
            title: "Submitted",
            text: " Trainer Assign Successfully",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.AddNewUser = false;
          this.assignTrainer = false;
          this.$router.push({ path: `/trainermanagement` });

          this.$forceUpdate();
        })
        .catch((error) => {
          let string = "";
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            string = error.response.data.message;
          }
          this.$swal({
            title: "Error",
            html: string,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          console.error("Error adding customer:", error);
        })
        .finally(()=>{
          this.submiting=false
        })
    },
    openPlanModal() {
      this.planRenew = true;
    },
    handleFormSubmission() {
      this.$refs.planRenewal.validate().then(async (success) => {
        if (success === false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Please fill in all fields",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.planRenewable();
        }
      });
    },
    planRenewable() {
        let endDate;

        switch (this.plans.id) {
          case 1:
            endDate = moment(this.originalDate, "DD/MM/YYYY")
              .add(1, "month")
              .format("YYYY/MM/DD");
            break;
          case 2:
            endDate = moment(this.originalDate, "DD/MM/YYYY")
              .add(3, "months")
              .format("YYYY/MM/DD");
            break;
          case 3:
            endDate = moment(this.originalDate, "DD/MM/YYYY")
              .add(6, "months")
              .format("YYYY/MM/DD");
            break;
          case 4:
            endDate = moment(this.originalDate, "DD/MM/YYYY")
              .add(9, "months")
              .format("YYYY/MM/DD");
            break;
          case 5:
            endDate = moment(this.originalDate, "DD/MM/YYYY")
              .add(12, "months")
              .format("YYYY/MM/DD");
            break;
          default:
            // Handle other cases or set a default behavior
            break;
        }
        let Id = this.$route.params.id
        
        const customerData = {
          id: Id,
          startdate: moment().format("YYYY-MM-DD"),
          enddate: endDate,
          plan_id: this.plans.id,
          action:'Approve'
        };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}dashboard/approve-reject-renewal-plan/${Id}`,
        data: JSON.stringify(customerData),
      };
      axios(requestOptions)
        .then((response) => {
          this.$swal({
            title: "Submitted",
            text: response.data.massage
              ? `${response.data.massage}`
              : "Client Updated Successfully",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.getClientData();
          this.selectPlan = false;
          this.$forceUpdate();
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.$swal({
              title: "Error!",
              text: error.response.data.message,
              icon: "error",
              timer: 5000,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          } else {
            this.$swal({
              title: "Error!",
              text: `${error}`,
              icon: "error",
              timer: 5000,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
        });
    },
    async getAllTrainer() {
      this.isDataLoading = true;
      let params = {};
      if (this.newSearch) {
        params.search = this.newSearch;
      }
      await axios({
        method: "GET",
        url: `${this.baseApi}trainer/get-all-trainer`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        params:params
      })
        .then((json) => {
          const paramId = parseInt(this.$route.params.id);
          const filteredTrainers = json?.data.data.filter(
            (trainer) => trainer.id !== paramId
          );
          this.AllTrainer = filteredTrainers;
          this.totalTairner = json?.data.data.total;
          this.isDataLoading = false;

          if(this.dropdownTrainers.length ==0){
            this.dropdownTrainers = JSON.parse(JSON.stringify(filteredTrainers));
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    removeVideo(index) {
      this.videosArray.splice(index, 1);
    },
    openModal(e) {
      this.suspend_modal = true;
      this.suspend_type = e;
      this.reason = "";
      this.weak = "";
    },
    openNewModal() {
      this.Parmasuspended = true;
      this.ParmaSuspendReason = "";
    },

    selectClient() {
      this.selected = this.trainersList;
      // console.log(this.selected);
      this.trainee = true;
      if (this.AddNewUser) {
        this.AddNewUser = false;
      }
      this.SubmitTrainer = true;
    },
    openClientModal() {
      this.selectedCustomers = [];
      this.AddNewUser = true;
    },
    close() {
      this.trainee = false;
      this.AddNewUser = false;
      this.assignTrainer = false;
    },

    closemodel() {
      this.changetrainer = false;
      this.trainee = false;
      this.suspened = false;
      this.Parmasuspended = false;
      this.suspend_modal = false;
      this.suspend_type = "";
      this.reason = "";
      this.week = "";
    },
  },
};
</script>
    
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.image {
  border: solid 4px black;
  height: 200px;
  width: 450px;
}

.button {
  font-weight: 400;
  font-family: "Public Sans", sans-serif;
  font-size: 15px;
}

.label {
  font-weight: 400;
  font-family: "Public Sans", sans-serif;
  font-size: 13px;
}

.details-label{
  font-weight: 600;
  font-family: 'Public Sans', sans-serif;
  font-size: 15px;
}
</style>
