<template>
    <vue-apex-charts
      type="area"
      height="500"
      width="100%"
      :options="option3"
      :series="option3.series"
      v-if="flag"
    />

</template>

<script>
import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/line'
import theme from './theme.json'
import VueApexCharts from 'vue-apexcharts'


ECharts.registerTheme('theme-color', theme)

export default {
  components: {
    ECharts,
    VueApexCharts
  },
  props: {
    optionData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      flag:false,
      // option2:{
      //   chart: {
      //     height: 280,
      //     type: "area",
      //     toolbar: {
      //       show: false // Set to false to hide the toolbar
      //     }
      //   },
      //   dataLabels: {
      //     enabled: false
      //   },
      //   series: [
      //     {
      //       name: "Weight",
      //       data: []
      //     }
      //   ],
      //   markers: {
      //     colors: [ "#FF6633"], // Change the colors of the markers
      //   },
      //   xaxis: {
      //     categories: []
      //   },
      //   stroke: {
      //     width: 5, // Adjust this value to make the line broader
      //     curve: 'smooth'
      //   },
      //   tooltip: {
      //     theme: "dark",
      //   },
      // },

      option3:{
        series: [{
            name: 'Weight',
            data: []
          }],
            chart: {
              height: 350,
              // type: 'area',
              zoom: {
                enabled: false
              },
              toolbar: {
                show: false // Set to false to hide the toolbar
              }
            },
            colors: [ "#FF6633"], // Change the colors of the markers

            markers: {
              colors: [ "#FF6633"], // Change the colors of the markers
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            xaxis: {
              categories: []
            },
            tooltip: {
              theme:'dark'
            },
          
      },

      option: {
        title: {
          text: 'Stacked area chart',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985',
            },
          },
        },
        legend: {
          left: '0',
        },
        grid: {
          width: '100%',
          left: '40px',
          right: '4%',
          containLabel: false,
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: [],
          },
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: { show: false },
          },
        ],
        series: [],
      },
    }
  },
  mounted(){
    this.option3.xaxis.categories= this.optionData.xAxisData
    this.option3.series[0].data= this.optionData.series[0].data
    // setTimeout(() => {
      this.flag=true
    // }, 500);
  },
}
</script>
