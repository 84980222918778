<template>
  <section id="card-images" class="container1">
    <h2 class=" mb-2 font-weigth-bolder Title">
      Video Approval
    </h2>
    <b-row>
      <b-col v-if="loadingPending" md="12" sm="12" lg="8" xl="8" class=" d-flex justify-content-center align-items-center">
        <div class="text-center text-secondary my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </b-col>
      <b-col v-else md="12" sm="12" lg="8" xl="8" class=" d-flex justify-content-center">
        <b-card style="width:700px;height:370px" class="cardImage">
          <div v-if="PendingVideos && PendingVideos.length > 0" class="w-10 h-100">
            <video class="PendingVideo" ref="videoPlayer" controls>
              <source :src="PendingVideos ? PendingVideos[0].url :''" type="video/mp4">
            </video>
            <div class="cardLeft" style="
              position: absolute;
              height: 60%;
              top: 15px;
              padding: 0px 10px;
      ">
              <b-media no-body>
                <b-media-aside class="mr-50">
                  <b-avatar class="border-white bg-transparent" style="padding:5px;height:60px"
                    :src="PendingVideos.admin_id ? PendingVideos.admin_id.image : (PendingVideos.user ? PendingVideos.user.profilephoto : '')" size="60" />
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h6 class="mb-0 text-white">
                    {{ data.title }}
                  </h6>
                  <!-- <small>2 Weeks ago</small> -->
                </b-media-body>
              </b-media>
              <b-card-text class="mt-2 text-content-videos" style="width:60%;line-height:30px">
                <div class="text-white bannertext font-weight-bolder">{{PendingVideos.title}}</div>
              </b-card-text>
              <div>
                <div class="bg-white d-flex align-items-center cardAction cursor-pointer border rounded"
                  @click="ApproveVideo('Approved', PendingVideos[0].id)">
                  <b-img class="cardImageInner" style="margin:7px" fluid :src="right" alt="Login V2" />
                  <span class="cardTextInner text-dark">Approve Video</span>
                </div>
                <div class="bg-white d-flex align-items-center mt-1 cardAction cursor-pointer rounded"
                  @click="ApproveVideo('Rejected', PendingVideos[0].id)">
                  <b-img class="cardImageInner" style="margin:7px" fluid :src="close" alt="Login V2" />
                  <span class="cardTextInner text-dark">Reject Video</span>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="no-found-message">
            No pending video available
          </div>
        </b-card>
      </b-col>
      <b-col md="8" sm="12" lg="4" xl="4">
        <div class="containerimage bg-gray">
          <div class="content" @drop="handleDrop">
            <div class="content-overlay1"></div>
            <img class="content-image" :src="videomanagementupload" alt="">
            <div class="content-details fadeIn-bottom1 d-flex  flex-column align-items-center">
              <div class="d-flex justify-content-center w-100">

                <b-button @click="addVideoHandelClick()" variant="outline-dark" class="btn-icon bg-light">
                  <feather-icon icon="PlusCircleIcon" size="30" />
                </b-button>
                <!-- <b-button @click="addVideoHandelClick" varient="outline-dark">
                  <feather-icon icon="PlusCircleIcon" size="30"/>
                </b-button> -->
              </div>
              <strong class="pt-3 text-light w-100">Drop files here or click to upload</strong>
              <div class="text-light">
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- <div class="file-uploader">
      <div class="drop-area" @drop="handleDrop" @dragover.prevent>
        <p>Drag and drop files here</p>
      </div>
      <input type="file" @change="handleFileInput" />
      <ul>
        <li v-for="file in uploadedFiles" :key="file.name">{{ file.name }}</li>
      </ul>
    </div> -->
    <b-row class="my-2 d-flex justify-content-between">
      <b-col md="4">
        <h2 class="font-weight-bolder text-dark videoTitle">
          Pending Videos
        </h2>
      </b-col>
      <b-col class="d-flex justify-content-end">
        <b-link to="/workout-pending-videos" class="font-weight-bold viewAll">View All</b-link>
      </b-col>
    </b-row>
    <!-- <button @click="playPauseVideo">{{ isPlaying ? 'Pause' : 'Play' }}</button> -->
    <b-row>
      <div v-if="isSideDataLoading" style="height:200px;" class="d-flex justify-content-center align-items-center w-100">
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <div v-else-if="PendingVideos && PendingVideos.length > 0" class="w-100 d-flex">
          <b-col md="4" xl="3" :key="index" v-for="(item, index) in FindPanding">
            <b-card class="videomanagement">
              <div class="w-100">
                <div v-if="PendingVideos && PendingVideos.length > 0">
                  <b-img v-if="isImage(item.url)" class="text-dark w-100 ImageLink" :src="item.url"
                    :alt="item.thumbnail" />
                  <video v-else class="text-dark w-100 ImageLink" ref="videoPlayer" controls>
                    <source :src="item.url" type="video/mp4">
                  </video>
                  <div class="p-2 ImageUrl" :style="!isImage(item.url) ? 'margin-top:-6px' : ''">
                    <div class="d-flex justify-content-between">
                <span class="text-muted mt-1" style="font-size:15px;">
                  {{ item.admin ? item.admin.f_name : (item.user ? item.user.f_name : '') }}
                </span>
                <span class="profile-image-wrapper">
                  <b-media no-body class="mt-1">
                    <b-media-aside>
                      <b-avatar class="border-light bg-transparent" style="padding:5px;position: relative;bottom: 20px;"
                        :src="item.admin ? item.admin.image : (item.user ? item.user.profilephoto : '')"  size="50" />
                    </b-media-aside>
                  </b-media>
                </span>
                </div>
                    <h4 class="text-white font-weight-bolder mt-1">"{{ item.title }}"</h4>
                    <div class="d-flex justify-content-end align-items-center mt-1">
                      <!-- <div class="text-muted" style="font-size:13px">
                        {{ kFormatter(item.view_count ? item.view_count : 0) }} views
                      </div> -->
                      <b-badge class="profile-badge" v-if="item.delete" variant="light-danger">
                        Removed
                      </b-badge>
                      <b-button @click="removeVideo(item.id)" variant="outline-danger" size="sm"
                        class="font-weight-bolder ml-1">
                        Remove
                      </b-button>
                      <!-- <svg v-else xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#d85f5f" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                    <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
                  </svg> -->
                    </div>
                  </div>
                </div>
              </div>
            </b-card>
          </b-col>
        </div>
        <div v-else class="w-100 no-found-message" style="height:70px">
          No pending video found
        </div>
    </b-row>
    <!--/ follower projects rank -->
    <!-- Most Watched -->
    <b-row class="my-2 d-flex justify-content-between">
      <b-col md="4">
        <h2 class="font-weight-bolder text-dark videoTitle">
          Most Watched
        </h2>
      </b-col>
      <b-col class="d-flex justify-content-end">
        <b-link to="/workout-MostWatched-videos" class="font-weight-bold viewAll">View All</b-link>
      </b-col>
    </b-row>
    <b-row>
      <div class="w-100" style="overflow-x: auto;">
        <div v-if="isMostLoading" style="height:400px" class="d-flex justify-content-center align-items-center">
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <div v-else-if="MostVideos && MostVideos.length > 0" class="w-100 d-flex">
          <b-col md="4" xl="3" :key="index" v-for="(item, index) in MostVideos.slice(0, 4)">
            <b-card class="videomanagement">
              <b-img v-if="isImage(item.url)" class="w-100 text-dark ImageLink" :src="item.url" :alt="item.thumbnail" />
              <video v-else class="w-100 text-dark ImageLink" ref="videoPlayer" controls>
                <source :src="item.url" type="video/mp4">
              </video>
              <div class="p-2 imageUrl" :style="!isImage(item.url) ? 'margin-top:-6px' : ''">
                <div class="d-flex justify-content-between">
                <span class="text-muted mt-1" style="font-size:15px;">
                  {{ item.admin ? item.admin.f_name : (item.user ? item.user.f_name : '') }}
                </span>
                <span class="profile-image-wrapper">
                  <b-media no-body class="mt-1">
                    <b-media-aside>
                      <b-avatar class="border-light bg-transparent" style="padding:5px;position: relative;bottom: 20px;"
                        :src="item.admin ? item.admin.image : (item.user ? item.user.profilephoto : '')" size="50" />
                    </b-media-aside>
                  </b-media>
                </span>
                </div>
                <h4 class="text-white font-weight-bolder" style="margin-top:10px">"{{ item.title }}"</h4>
                <div class="d-flex justify-content-end align-items-center mt-1">
                  <!-- <div class="text-muted" style="font-size:13px">
                    {{ kFormatter(item.view_count ? item.view_count : 0) }} views
                  </div> -->
                  <b-badge class="profile-badge" v-if="item.delete" variant="light-danger">
                    Removed
                  </b-badge>
                  <b-button @click="removeVideo(item.id)" variant="outline-danger" size="sm"
                    class="ml-1 font-weight-bolder">
                    Remove
                  </b-button>
                </div>
              </div>
            </b-card>
          </b-col>
        </div>
        <div v-else class="no-found-message w-100" style="height:70px">
          No Most watched videos found
        </div>
      </div>
    </b-row>


    <!-- All Videos -->
    <b-row class="my-2 d-flex justify-content-between">
      <b-col md="4">
        <h2 class="font-weight-bolder text-dark videoTitle">
          All Videos
        </h2>
      </b-col>
      <b-col class="d-flex justify-content-end">
        <b-link to="/workout-AllVideo-videos" class="font-weight-bold viewAll">View All</b-link>
      </b-col>
    </b-row>
    <b-row>
      <div class="w-100" style="overflow-x: auto;">
        <div v-if="isAllVideoLoading" style="height:400px" class="d-flex justify-content-center align-items-center">
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <div v-else-if="videosArray && videosArray.length > 0" class="w-100 d-flex">
          <b-col sm="6" md="4" xl="3"  :key="index" v-for="(item, index) in videosArray.slice(0, 4)">
            <b-card class="videomanagement">
              <b-img v-if="isImage(item.url)" class="w-100 text-dark imageLink" style="height: 217px;" :src="item.url" :alt="item.thumbnail" />
              <video v-else class="w-100 text-dark imageLink" ref="videoPlayer" controls style="height: 217px;">
                <source :src="item.url" type="video/mp4">
              </video>
              <div class="p-2 imageUrl" :style="!isImage(item.url) ? 'margin-top:-6px' : ''" style="height:217px">
                <div class="d-flex justify-content-between">
                <span class="text-muted mt-1" style="font-size:15px;">
                  {{ item.admin ? item.admin.f_name : (item.user ? item.user.f_name : '') }}
                </span>
                <span class="profile-image-wrapper">
                  <b-media no-body class="mt-1">
                    <b-media-aside>
                      <b-avatar class="border-light bg-transparent" style="padding:5px;position: relative;bottom: 20px;"
                        :src="item.admin ? item.admin.image : (item.user ? item.user.profilephoto : '')" size="50" />
                    </b-media-aside>
                  </b-media>
                </span>
                </div>               
                <h4 class="text-white font-weight-bolder mt-1">"{{ item.title }}"</h4>
                <div class="d-flex justify-content-end align-items-center mt-1">
                  <!-- <div class="text-muted" style="font-size:13px">
                    {{ kFormatter(item.view_count ? item.view_count : 0) }} views
                  </div> -->
                  <b-badge class="profile-badge" v-if="item.delete" variant="light-danger">
                    Removed
                  </b-badge>
                  <b-button @click="removeVideo(item.id)" variant="outline-danger" size="sm"
                    class="ml-1 font-weight-bolder">
                    Remove
                  </b-button>
                  <!-- <svg v-else xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#d85f5f"
                class="bi bi-trash3-fill" viewBox="0 0 16 16">
                <path
                d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
              </svg> -->
                </div>
              </div>
            </b-card>
          </b-col>
        </div>
        <div v-else class="no-found-message w-100" style="height:70px">
          No All videos found
        </div>
      </div>
    </b-row>

    <b-modal v-model="modal" hide-footer ok-title="Save" no-close-on-backdrop @hide="onModalClose" size="lg" title-class="font-weight-300">
      <div v-if="!loadingModel">
        <div class="d-flex justify-content-center align-items-center flex-column">
          <h3>Upload Video</h3>
          <h6 class="text-muted">Please provide more details about your workout video.</h6>
        </div>
        <validation-observer v-if="!isupload" ref="simpleRules">
          <b-form @submit.prevent="submitForm">
            <b-row>
              <b-col md="12" class="mt-2">
                <b-form-group>
                  <validation-provider #default="{ errors }" rules="required" name="Title">
                    <b-form-input v-model="Title" placeholder="Write Title" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group>
                  <label class="font-weight-bold mt-1" 
                    >Upload Thumbnail</label
                  >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Thumbnail"
                  >
                    <b-form-file
                      v-model="uploadThumbnail"
                      placeholder="Upload Photo"
                      @input="uploadDoc($event, 'uploadThumbnail', 'Video')"
                    ></b-form-file>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group class="d-flex">
                  <label class="font-weight-bold mt-1" >Open For All ?</label>
                  <b-form-checkbox
                    @input="handleOpen($event)"
                    class="mr-0"
                    switch
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <h5>Select Video Category</h5>
              </b-col>

              <div v-if="isLoadingCategory" style="height:200px" class="w-100 d-flex justify-content-center align-items-center">
                <b-spinner label="Loading..."></b-spinner>
              </div>
              <div v-else class="w-100" style="overflow-y:scroll; height:500px">
                <b-col md="12" class="mt-1" v-for="item in categories" :key="item.id">
                  <div class="d-flex justify-content-between w-100" >
                    <div>
                      <b-media no-body  class="d-flex align-items-center">
                        <b-media-aside>
                          <b-avatar rounded size="42" variant="light-danger">
                            <feather-icon size="18" icon="WindIcon" />
                          </b-avatar>
                        </b-media-aside>
                        <b-media-body>
                          <h6 class="transaction-title">
                            {{ item.name }}
                          </h6>
                          <!-- <small>Scales with any business</small> -->
                        </b-media-body>
                      </b-media>
                    </div>
                    <input type="checkbox" @input="handleCategory($event,item)"  :checked="categories.selected?true:false" style="height:20px; width:20px" :value="item.id" />
                  </div>
                </b-col>
              </div>
            </b-row>
          </b-form>
        </validation-observer>
        <b-row v-else>
          <b-col md="12">
            <b-form-file v-model="image" id="fileUpload" accept="video/*" @input="handleFileInput($event)" />
          </b-col>
        </b-row>
        <b-button class="float-right mt-1" variant="primary" :disabled="flag" @click="uploadVideo()" v-if="files.length > 0">
          Upload
        </b-button>
        <b-button class="float-right mt-1" variant="primary" @click="ClickTONext" v-else>
          Next
          <feather-icon size="18" icon="ArrowRightIcon" />
        </b-button>
      </div>
      <div class="d-flex justify-content-center align-items-center" style="height:300px" v-else>
        <b-spinner class="mr-1" label="Small Spinner" />
      </div>
    </b-modal>

  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCardText,
  BCardTitle,
  BForm,
  BEmbed,
  BCard,
  BCardBody,
  BCardHeader,
  BImg,
  BLink,
  BSpinner,
  BMedia,
  BMediaBody,
  BMediaAside,
  BAvatar,
  BAvatarGroup,
  VBTooltip,
  BBadge,
  BButton,
  BFormInput,
  BFormGroup,
  BFormFile,
  BFormRadio,
  BFormCheckbox
} from "bootstrap-vue";
import { kFormatter } from "@core/utils/filter";
import videomanagementImage from "../../../../assets/images/svg/videomanagement.png";
import right from "../../../../assets/images/svg/right.svg";
import close from "../../../../assets/images/svg/close.svg";
import videomanagementposter from "../../../../assets/images/svg/videomanagementposter.png";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import videomanagementupload from "../../../../assets/images/svg/videomanagementupload.png";
import axios from "@/component/axios";
import vSelect from "vue-select";
import { required } from "@validations";
import _ from "lodash";
export default {
  components: {
    BFormRadio,
    BRow,
    BCol,
    BCardText,
    BCardTitle,
    BEmbed,
    BCard,
    BCardBody,
    BCardHeader,
    BImg,
    BMedia,
    BMediaBody,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    VBTooltip,
    BBadge,
    BButton,
    BFormFile,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    BForm,
    BLink,
    BSpinner,
    ValidationObserver,
    BFormRadio,
    BFormCheckbox
  },
  data() {
    return {
      playerOptions: {
        autoplay: false,
        controls: true,
        sources: [
          {
            src: "https://example.com/your-video.mp4",
            type: "video/mp4",
          },
        ],
      },
      open:false,
      flag:false,
      loadingPending: false,
      isSubCategory: false,
      isLoading: false,
      isLoadingCategory: false,
      capturedImage: {},
      uploadedFiles: [],
      isSideDataLoading: false,
      kFormatter,
      vSelect,
      isMostLoading: false,
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      Selected: null,
      modal: false,
      image: "",
      categories: [],
      videosArray: [],
      files: [],
      PendingVideos: [],
      MostVideos: [],
      isPlaying: false,
      isupload: false,
      Title: "",
      loadingModel: false,
      required,
      videomanagementImage,
      right,
      isAllVideoLoading: false,
      close,
      videomanagementupload,
      videomanagementposter,
      id: 0,
      other:'',
      data: [],
      subcategories: [],
      selectSubCategories:"",
      uploadThumbnail:"",

      // displayedVideos: 4,
    };
  },

  computed: {
    numbers() {
      return Array.from({ length: 20 }, (_, index) => index + 1);
    },
    FindPanding() {
      if (this.PendingVideos.length > 4) {
        return this.PendingVideos.slice(0, 4);
      } else {
        return this.PendingVideos;
      }
    },
  },
  mounted() {
    this.getCategories();
    this.getVideo();
    this.getPendingVideo();
    this.getMostVideo();
  },
  methods: {
    handleOpen(e) {
      this.open = e;
    },
    handleCategory(e,item) {
      item.selected = e
    },
    async uploadDoc(event, variable, name) {
      this.flag = true;
      if (event) {
        const formData = new FormData();
        formData.append("url", event);
        formData.append("type", "image");
        await axios({
          method: "POST",
          url: `${this.baseApi}attachments/uploadattachment`,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.accessToken}`,
          },
          data: formData,
        }).then(async (response) => {
          this[variable] = response.data.data.url;
        })
        .finally(()=>{
          this.flag = false;

        })
      }
    },
    playPauseVideo() {
      const video = this.$refs.videoPlayer;
      if (this.isPlaying) {
        video.pause();
      } else {
        video.play();
      }

      this.isPlaying = !this.isPlaying;
    },
    isImage(data) {
      const imageExtensions = [
        ".jpg",
        ".jpeg",
        ".png",
        ".gif",
        ".bmp",
        ".webp",
        ".svg",
        ".tiff",
      ];

      return imageExtensions.some((extension) =>
        String(data).endsWith(extension.toLowerCase())
      );
    },
    async getVideo() {
      this.isAllVideoLoading = true;
      await axios({
        method: "GET",
        url: `${this.baseApi}video/get`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((response) => {
          this.videosArray = response.data.data;
          this.isAllVideoLoading = false;
        })
        .catch((error) => console.log(error, "error"));
      this.isAllVideoLoading = false;
    },
    async getPendingVideo() {
      this.loadingPending = true;
      this.isSideDataLoading = true;
      await axios({
        method: "GET",
        url: `${this.baseApi}video/get-pending`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((response) => {
          this.PendingVideos = response.data.data.data;
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {
          this.isSideDataLoading = false;
          this.loadingPending = false;
        });
    },
    async getMostVideo() {
      this.isMostLoading = true;
      await axios({
        method: "Post",
        url: `${this.baseApi}video/get-most-watched`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((response) => {
          this.MostVideos = response?.data.data.data;
          this.isMostLoading = false;
        })
        .catch((error) => console.log(error, "error"));
      this.isSideDataLoading = false;
      this.loadingPending = false;
    },
    async uploadVideo() {
      if (this.files.length > 0) {
        const formData = new FormData();
        formData.append("url", this.files[0]);
        formData.append("type", "video");
        this.loadingModel = true;
        let arr = this.categories.filter(item=>item.selected)
        await axios({
          method: "POST", 
          url: `${this.baseApi}attachments/uploadattachment`,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.accessToken}`,
          },
          data: formData,
        }).then(async (response) => {
          let data = {
            url: response.data.data.url,
            title: this.Title,
            thumbnail: this.uploadThumbnail,
            admin_id: JSON.parse(localStorage.getItem("userData")).id,
            videocategory_ids: arr.map(item=>item.id).join(','),
            open:this.open?1:0,
            status: 1,
          };
          await axios({
            method: "POST",
            url: `${this.baseApi}video/upload`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.accessToken}`,
            },
            data: data,
          })
            .then((response) => {
              if (response.data.success) {
                this.$swal({
                  title: "Submited",
                  text: "Video Uploaded Successfully",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
                this.getPendingVideo();
                this.getVideo();
                this.getMostVideo();
                this.modal = false;
                this.Title = "";

                this.image = "";
              } else {
                (this.loadingModel = false),
                  this.$swal({
                    title: "Error",
                    text: "Try Again",
                    icon: "error",
                    customClass: {
                      confirmButton: "btn btn-danger",
                    },
                    buttonsStyling: false,
                  });
              }
            })
            .catch((error) => {
              this.loadingModel = false;
            });
        });
      } else {
        this.$swal({
          title: "Warning",
          timer: 5000,
          text: "Plese Fill the All Field",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    },

    ClickTONext() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          this.isupload = true;
        } else {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Please fill in all fields",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    ApproveVideo(e, id) {
      const customerData = {
        id: id,
        approve: e,
      };

      let confirmation;
      if (e === "Approved") {
        confirmation = "Yes, Approve it!";
      } else {
        confirmation = "Yes, Reject it!";
      }

      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: confirmation,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const requestOptions = {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.accessToken}`,
            },
            url: `${this.baseApi}video/approval-request`,
            data: JSON.stringify(customerData),
          };

          axios(requestOptions)
            .then((response) => {
              this.$swal({
                title: "Submitted",
                text:
                  response.data.message ||
                  response.data.success ||
                  "Password Updated Successfully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.$forceUpdate();
              this.getPendingVideo();
            })
            .catch((error) => {
              const errorMessage =
                error.response?.data?.message ||
                error.message ||
                "An error occurred!";
              this.$swal({
                title: "Error!",
                text: errorMessage,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },

    async removeVideo(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then(async (result) => {
          if (result.value) {
            await axios({
              method: "Delete",
              url: `${this.baseApi}video/delete/${id}`,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.accessToken}`,
              },
            })
              .then(() => {
                  this.$swal({
                    title: "Deleted",
                    text: "Video deleted successfully",
                    icon: "success",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  });
                  this.$forceUpdate();
                    this.getMostVideo();
                    this.getPendingVideo();
                    this.getVideo();
              })
              .catch((error) => {
                console.error("Error deleting user:", error);
              });
          }
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {});
    },
    onModalClose() {
      this.files = [];
      (this.isupload = false), (this.Title = "");
      this.categories.map((item) => {
          item.selected = false
      })
      this.selectSubCategories = null;
      this.image = "";
    },
    handleRadioChange(value) {},
    async getCategories() {
      this.isLoadingCategory = true;
      await axios({
        method: "GET",
        url: `${this.baseApi}videocategories/all`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((response) => {
          this.categories = response.data.data;
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {
          this.isLoadingCategory = false;
        });
    },
    //  async addSubVideo() {
    //    this.isSubCategory = true;
    //    // if (!id) {
    //    //   console.error("Category ID is undefined");
    //    //   return;
    //    // }
    //    let subId = this.Selected;
    //    await axios({
    //      method: "GET",
    //      url: `${this.baseApi}workout/getSub/${subId}`,
    //      headers: {
    //        "Content-Type": "application/json",
    //        Authorization: `Bearer ${this.accessToken}`,
    //      },
    //    })
    //      .then((response) => {
    //        this.subcategories = response.data;
    //        this.subcategories.push("Other")
    //        // this.modal = true
    //      })
    //      .catch((error) => {
    //        console.error(error);
    //      })
    //      .finally(() => {
    //        this.isSubCategory = false;
    //      });
    //  },
    addVideoHandelClick() {
      this.modal = true;
      // this.addVideo();
    },
    handleDrop(event) {
      event.preventDefault();
      this.files = event.dataTransfer.files;
      if (this.files.length > 1) {
        this.$swal({
          title: "Warning",
          text: `Please Select Only One Image`,
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-warning",
          },
          buttonsStyling: false,
        });
        return;
      }
      // else {
      //   this.file = this.files[0]
      //  }
      // this.addVideo();
      this.uploadedFiles = [...this.uploadedFiles, ...Array.from(files)];
    },
    handleFileInput(event) {
      this.files[0] = event;
    },
  },
};
</script>
<style>
.file-uploader {
  text-align: center;
}

.drop-area {
  border: 2px dashed #ccc;
  padding: 20px;
}

.PendingVideo {
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 0px 0px;
  color: black;
}

.videoTitle {
  color: #000000;
  font-weight: 500;
  font-size: 28px;
  font-family: "Public Sans", sans-serif;
}

.viewAll {
  font-size: 24px;
  font-family: "Public Sans", sans-serif;
}

.ImageLink {
  height: 180px;
  border-radius: 10px 10px 0px 0px;
}

.imageUrl {
  background-color: #242730;
  border-radius: 0px 0px 10px 10px;
}

.Title {
  color: #000000;
  font-size: 40px;
  font-family: "Public Sans", sans-serif;
}

.cardImage .card-body {
  padding: 0px;
}

.cardImage .card {
  padding: 0px !important;
}

.videomanagement .card-body {
  padding: 0px !important;
  text-align: start !important;
  color: white;
  border-radius: 15px;
}

.cardImage {
  background-size: 100% 100%;
  background-color: #353847;
}

@media screen and (min-width: 1200px) {
  .videoHeader {
    font-size: 20px;
  }

  .videoHeader1 {
    font-size: 24px;
  }

  .cardImage {
    min-height: 10cm;
  }

  .bannertext {
    font-size: 25px;
  }

  .cardImageInner {
    width: 25px;
  }

  .cardAction {
    width: 165px;
  }

  .cardLeft {
    min-height: 8.5cm;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}

@media screen and (max-width: 1200px) {
  .videoHeader {
    font-size: 17px;
  }

  .videoHeader1 {
    font-size: 20px;
  }

  .cardImage {
    min-height: 7cm;
  }

  .bannertext {
    font-size: 20px;
  }

  .cardImageInner {
    width: 20px;
  }

  .cardTextInner {
    font-size: 13px;
  }

  .cardAction {
    width: 150px;
  }

  .cardLeft {
    min-height: 5.6cm;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .videoHeader {
    font-size: 15px;
  }

  .videoHeader1 {
    font-size: 17px;
  }

  .cardImage {
    min-height: 4cm !important;
  }

  .bannertext {
    font-size: 15px;
  }

  .cardImageInner {
    width: 15px;
  }

  .cardTextInner {
    font-size: 12px;
  }

  .cardAction {
    width: 135px;
  }

  .cardLeft {
    min-height: 4cm !important;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}

@media screen and (max-width: 576px) {
  .text-content-videos {
    display: none;
  }

  .videoHeader {
    font-size: 13px;
  }

  .videoHeader1 {
    font-size: 16px;
  }
}

.containerimage .title {
  text-align: center;
  margin-bottom: 10px;
}

.containerimage .content {
  position: relative;
  width: 90%;
  max-width: 400px;
  margin: to;
  overflow: hidden;
}

.containerimage .content-overlay1 {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

/* .containerimage .content:hover .content-overlay1 {
  opacity: 1;
} */

.containerimage .content .content-overlay1 {
  opacity: 1;
}

.containerimage .content-image {
  width: 100%;
}

.containerimage .content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

/* 
.containerimage .content:hover .content-image {
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  filter: blur(5px);
  You can adjust the blur amount as needed 
}*/
.containerimage .content .content-image {
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  filter: blur(5px);
  /* You can adjust the blur amount as needed */
}

/*
.containerimage .content:hover .content-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.containerimage .content:hover .content-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}
*/
.containerimage .content .content-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.containerimage .content .content-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.containerimage .content-details h3 {
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.15em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

.containerimage .content-details p {
  color: #fff;
  font-size: 0.8em;
}

.containerimage .fadeIn-bottom1 {
  top: 80%;
}

.containerimage .fadeIn-top {
  top: 20%;
}

.containerimage .fadeIn-left {
  left: 20%;
}

.containerimage .fadeIn-right {
  left: 80%;
}

.no-found-message {
  height: 10cm;
  width: 100%;
  display: grid;
  place-items: center;
  font-size: 25px;
}
</style>