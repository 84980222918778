<template>
  <b-card class="w-100">
    <app-echart-stacked-area :option-data="option" style="width:58rem"/>

  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import AppEchartStackedArea from '@core/components/charts/echart/AppEchartStackedArea.vue'

export default {
  components: {
    BCard,
    AppEchartStackedArea,
  },
  data() {
    return {
      option: {
        xAxisData: ['1 Week', '2 Week', '3 Week', '4 Week', '5 Week', '6 Week', '7 Week', '8 Week','9 Week','10 Week'],
        series: [
          {
            type: 'line',
            stack: 'Total',
            areaStyle: {
              color: '#299aff',
            },
            color: '#299aff',
            showSymbol: false,
            smooth: 0.4,
            lineStyle: {
              width: 0,
            },
            data: [20, 60, 65, 40, 75, 50, 85, 60, 95, 100],
          },          
        ],
      },
    }
  },
}
</script>
